.header {
  margin-bottom: 170px;
  padding-top: 171px;
  @include md {
    margin-bottom: 14vw;
    padding-top: 0; }
  &__title {
    margin-bottom: 20px;
    @include md {
      margin: 0 auto 2vw;
      font-size: 3em; }
    @include r(1000) {
      font-size: 2.5em; }
    @include xs {
      font-size: 1.7em; } }
  &__main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // flex-wrap: wrap
    padding: 44px 48px 85px;
    position: relative;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    transition: padding 0.3s, background-color 0.3s;
    @include md {
      // padding: 4.5vw 0
      padding: 10px 0;
      padding-right: 6vw;
      padding-left: 35vw;
      background-color: #101010; }
    @include xs {
      padding-left: 45vw; }
    &.is-small {
      @include rmin(1126) {
        background-color: #101010;
        padding: 20px 48px; } } }
  &__logo {
    width: 98px;
    margin-left: auto;
    flex-grow: 0;
    flex-shrink: 0;
    @include md {
      width: 14vw; }
    @include r(800) {
      width: 18vw; }
    @include xs {
      width: 20vw; }
    @include r(400) {
      width: 22vw; } }
  &__descr {
    color: rgba(white, .8);
    @include md {
      font-size: 1.6em;
      max-width: 88%;
      margin-left: auto;
      margin-right: auto; }
    @include r(1000) {
      max-width: 80%; }
    @include xs {
      font-size: 1em; } }
  &__content {
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
    @include md {
      max-width: 74%; }
    @include r(1000) {
      max-width: 76%; }
    @include r(800) {
      max-width: 80%; }
    @include sm {
      max-width: 90%; }
    @include xs {
      max-width: 100%; } }
  &__ramadan {
    max-width: 295px;
    margin: 0 auto 36px;
    @include md {
	    max-width: 100%;
	    width: 36vw; } }
  &__sticky-text {
    position: absolute;
    left: 0%;
    top: 25%;

    padding: 0 98px;
    width: 100%;
    text-align: center;
    transition: opacity 0.3s;
    opacity: 0;
    z-index: -1;
    @include md {
      display: none;
      position: static;
      flex-grow: 1;
      flex-shrink: 1;
      padding: 0 20px;
      width: auto;
      text-align: left; }

    strong {
      color: #fff;
      @include dinarBold;
      font-size: 24px;
      @include md {
        font-size: 18px; }
      @include xs {
        font-size: 12px; } }
    &.is-visible {
      opacity: 1; } } }
