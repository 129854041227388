.movie {
  $this: &;

  overflow: hidden;
  position: relative;
  transition: .3s ease;
  transform-origin: 50% 100%;
  transform: scale(1) rotateX(0);
  background-color: transparent;
  height: 0;
  &.is-image-loading {
    background-color: black;
    #{$this}__link, #{$this}__checkbox {
      display: none; } }
  &__image {
    @include coverdiv;
    font-size: 0;
    transition: .3s ease;
    transform: translate3d(0,0,0);
    img {
      opacity: 0;
      transform: scale(0.5);
      transition: .5s ease;
      &.is-loaded {
        opacity: 1;
        transform: scale(1); } } }

  &__link {
    background-color: rgba(black, .59);
    color: #86c800;
    text-transform: uppercase;
    font-size: 16.5px;
    text-align: center;
    padding: 13px 10px 14px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    transform: translateY(100%);
    transition: .25s ease;
    text-decoration: none;
    @include md {
      width: 4.5vw;
      height: 4.5vw;
      border-radius: 50%;
      position: absolute;
      top: 2%;
      left: 2%;
      right: auto;
      transform: none;
      transition: none;
      color: rgba(white, 0);
      padding: 0;
      &:before {
        content: '';
        display: block;
        width: 2.667vw;
        height: 2.667vw;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -1.3vw;
        margin-left: -1.3vw;
        border-radius: 50%;
        border: 1px solid #fff; }
      &:after {
        content: 'i';
        @include reg;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        font-size: 2vw;
        text-transform: lowercase; } }
    @include xs {
      width: 6.972vw;
      height: 6.972vw;
      &:before {
        width: 3.486vw;
        height: 3.486vw;
        margin-top: -1.743vw;
        margin-left: -1.743vw; }
      &:after {
        font-size: 2.4vw; } } }
  &__checkbox {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    background-color: transparent;
    label {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      cursor: pointer; }
    input {
      display: none;
      // height: 1px
      &:checked {
        ~ #{$this}__selected, ~ #{$this}__cover {
          visibility: visible;
          opacity: 1; }
        + #{$this}__select {
          display: none; } } } }
  &__cover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(black, .73);
    opacity: 0;
    visibility: hidden;
    transition: .25s ease;
    z-index: -2; }
  &__select {
    visibility: hidden;
    opacity: 0;
    transition: .25s ease;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 56px;
    height: 56px;
    border-radius: 50%;
    border: 2px solid rgba(#fff, .8);
    z-index: -1;
    pointer-events: none;
    &:before, &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      background-color: rgba(#fff, .8); }
    &:before {
      width: 2px;
      height: 26px;
      margin-top: -13px;
      margin-left: -1px;
      @include md {
        height: 3.2vw;
        margin-top: -1.6vw; } }
    &:after {
      width: 26px;
      height: 2px;
      margin-top: -1px;
      margin-left: -13px;
      @include md {
        width: 3.2vw;
        margin-left: -1.6vw; } }
    @include md {
      width: 6.6vw;
      height: 6.6vw;
      visibility: visible;
      opacity: 1;
      top: auto;
      transform: translateX(-50%);
      bottom: 2.8vw;
      margin-top: 0;
      border-width: 1px; } }
  &__selected {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -28px;
    margin-left: -28px;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    border: 2px solid #8bcf01;
    z-index: -1;
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 12px;
      height: 24px;
      border-right: 2px solid #8bcf01;
      border-bottom: 2px solid #8bcf01;
      transform: rotate(45deg);
      margin-top: -14px;
      margin-left: -6px; }
    @include md {
      width: 6vw;
      height: 6vw;
      margin-top: -3vw;
      margin-left: -3vw;
      &:before {
        width: 1.2vw;
        height: 2.4vw;
        margin-top: -1.2vw;
        margin-left: -.6vw; } } }
  @include hover {
    #{$this}__link {
      transform: translateY(0); }
    #{$this}__select {
      visibility: visible;
      opacity: 1; } }
  @include hover {
    &:not(.is-image-loading) {
      transform: scale(0.97) rotateX(-3deg);
      #{$this}__image {
        opacity: 0.7; } } } }

