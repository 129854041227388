.icon-facebook {
  width: 0.53em;
  height: 1em;
  fill: none;
}
.icon-instagram {
  width: 1em;
  height: 1em;
  fill: none;
}
.icon-lamp {
  width: 0.07em;
  height: 1em;
  fill: none;
}
.icon-share {
  width: 0.93em;
  height: 1em;
  fill: none;
}
.icon-star1 {
  width: 1.29em;
  height: 1em;
  fill: none;
}
.icon-star2 {
  width: 1.31em;
  height: 1em;
  fill: none;
}
.icon-star3 {
  width: 1.2em;
  height: 1em;
  fill: none;
}
.icon-twitter {
  width: 1.23em;
  height: 1em;
  fill: none;
}
