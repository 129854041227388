.socials {
  margin: 0 -7px;
  display: flex;
  @include md {
    margin: 0 -.7vw; }
  @include xs {
    margin: 0;
    flex-wrap: wrap; }
  &__item {
    width: 33.33%;
    padding: 0 7px;
    @include md {
      padding: 0 .7vw; }
    @include xs {
      width: 100%;
      padding: 0;
      &:not(:last-child) {
        margin-bottom: 10px; } } } }
