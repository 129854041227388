body {
	background-color: #101010;
	font-size: 14px;
	@include reg;
	line-height: 1.4;
	&.ar {
    @include dinarMedium; } }
.container {
	padding: 0 48px;
	@include xs {
		padding: 0 16px; } }
.out {
	overflow: hidden;
	position: relative;
	@include md {
		padding-top: 18vw; }
	@include xs {
		padding-top: 23vw; }
	&_gutter {
		overflow: hidden;
		@include md {
			padding-top: 20vw; }
		@include xs {
			padding-top: 35vw; } } }
.rtl {
	direction: rtl; }
.ltr {
	direction: ltr; }
.img-responsive {
	max-width: 100%;
	height: auto; }
.title {
	font-size: 45px;
	line-height: 1.2;
	color: #fff;
	@include semi;
	body.ar & {
		@include dinarBold; } }
.descr {
	font-size: 21px;
	color: #fff;
	@include light;
	body.ar & {
		@include dinarMedium; } }
.link {
	color: #84c205;
	text-decoration: none; }
.fadeIn {
	opacity: 0;
	transform: translateY(30px);
	transition: 0.6s ease; }
.wf-active, .wf-inactive {
	.fadeIn {
		opacity: 1;
		transform: translateY(0); } }
#sparks {
	position: absolute;
	z-index: 100;
	pointer-events: none; }
.show-md {
	display: none;
	@include md {
		display: inline-block; } }
.hide-md {
	display: inline-block;
	@include md {
		display: none; } }
