.coming-soon {
  margin-bottom: 230px;
  padding: 0 42px;
  @include md {
    margin-bottom: 24vw; }
  @include xs {
    padding: 0; }
  &__title {
    margin-bottom: 150px;
    text-align: center; }
  &__list {
    display: flex;
    margin: 0 -42px;
    @include md {
      margin: 0 -5.22%;
      display: none; }

    @include sm {
      margin: 0 -4%; }
    &_mob {
    	display: none;
    	@include md {
    		display: flex; } } }

  &__column {
    padding: 0 42px;
    width: 33.33%;
    @include md {
      max-width: 50%;
      min-width: 50%;
      padding: 0 5.22%;
      &:nth-child(3) {
        display: none; } }
    @include sm {
      padding: 0 4%; } }
  &__item {
    margin-bottom: 80px;
    font-size: 0;
    @include md {
      margin-bottom: 15vw; } }
  &__container {
    max-width: 1428px;
    margin: 0 auto;
    padding: 0 42px;
    @include md {
      padding: 0 7.5%; }
    @include sm {
      padding: 0 3%; } } }
