.movies {
  margin-bottom: 200px;
  padding: 0 42px;
  position: relative;
  @include md {
    margin-bottom: 24vw; }
  @include xs {
    padding: 0; }
  &__container {
    max-width: 1428px;
    margin: 0 auto;
    padding: 0 42px;
    @include md {
      padding: 0 7.5%; }
    @include sm {
      padding: 0 3%; } }

  &__form {
    display: flex;
    margin: 0 -42px;
    @include md {
      margin: 0 -5.22%; }
    @include sm {
      margin: 0 -4%; } }
  &__column {
    padding: 0 42px;
    max-width: 33.33%;
    min-width: 33.33%;
    @include md {
      max-width: 50%;
      min-width: 50%;
      padding: 0 5.22%;
      &:nth-child(3) {
        display: none; } }
    @include sm {
      &:nth-child(1) {
        padding: 0 2% 0 4%; }
      &:nth-child(2) {
        padding: 0 4% 0 2%; } } }
  &__item {
    margin-bottom: 84px;
    perspective: 800px;
    @include sm {
      margin-bottom: 8%; } }
  &_gutter {
    margin-bottom: 100px; } }
