@mixin reg {
  font-family: 'GillSans', Arial, Helvetica, sans-serif; }

@mixin semi {
  font-family: 'GillSans-SemiBold', Arial, Helvetica, sans-serif; }

@mixin light {
  font-family: 'GillSans-Light', Arial, Helvetica, sans-serif; }

@mixin dinarBold {
  font-family: 'GEDinarOne-Bold', GillSans-SemiBold, Arial, Helvetica, sans-serif; }

@mixin dinarMedium {
  font-family: 'GEDinarOne-Medium', GillSans, Arial, Helvetica, sans-serif; }
