.social {
  padding: 8px 10px;
  width: 100%;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  color: #fff;
  font-size: 17px;
  border-radius: 3px;
  @include semi;
  &_facebook {
    background-color: #385695; }
  &_twitter {
    background-color: #00a8ec; }
  &_inst {
    background-color: #ea0e51; }
  @include md {
    font-size: 1.85vw;
    padding: 1.823vw 10px; }
  @include xs {
    font-size: 3vw;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center; }
  @include r(400) {
    font-size: 4vw; } }
