.footer {
  background-color: black;
  padding-top: 158px;
  padding-bottom: 30px;
  @include md {
    padding-top: 13.6vw;
    padding-bottom: 13.6vw; }
  &__content {
    text-align: center;
    margin-bottom: 160px;
    @include md {
      margin-bottom: 0; } }
  &__title {
    margin-bottom: 47px;
    @include md {
      font-size: 6vw;
      padding: 0 6vw; } }
  &__bottom {
    display: flex;
    justify-content: flex-start; }
  &__copy {
    padding-left: 36px;
    font-size: 17px;
    letter-spacing: -.2px;
    color: rgba(white, .87);
    text-align: left;
    @include light;
    @include md {
      display: none; }
    body.ar & {
      @include dinarMedium; } }
  &__legal {
    color: #fff;
    @include light;
    font-size: 17px;
    margin-left: 20px;
    max-width: 500px;
    a {
      color: #fff;
      text-decoration: none; }
    @include md {
      display: none; } } }
