.tooltip {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
  background-color: rgba(black, .79);
  padding: 34px 0;
  display: flex;
  align-items: center;
  @include md {
    padding: 5vw 0; }
  &__container {
    max-width: 1428px;
    padding: 0 42px;
    margin: 0 auto;
    height: 100%;
    width: 100%;
    display: flex;
    padding-right: 140px;
    align-items: center;
    justify-content: space-between;
    @include md {
      padding-right: 4vw;
      padding-left: 4vw; } }
  &__title {
    font-size: 29px;
    @include semi;
    color: #fff;
    br {
      display: none; }
    body.ar & {
      @include dinarMedium; }
    @include md {
      font-size: 3.3vw;
      line-height: 1.2;

      br {
        display: block; } }
    @include xs {
      font-size: 4vw; } }
  &__button {
    color: #fff;
    border-radius: 5px;
    display: inline-block;
    text-decoration: none;
    background-color: #86c800;
    font-size: 24px;
    text-align: center;
    @include semi;
    padding: 6px 36px 6px;
    body.ar & {
      @include dinarMedium; }
    @include md {
      font-size: 2.5vw;
      padding: 1.3vw 3vw;
      min-width: 36vw; }
    @include xs {
      padding: 2.2vw 3vw;
      min-width: 40vw;
      font-size: 4vw; } } }
