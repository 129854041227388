.btn {
  border-radius: 5px;
  display: inline-block;
  text-decoration: none;
  font-size: 29px;
  padding: 9px 45px 6px;
  color: #fff;
  @include light;
  body.ar & {
    @include dinarMedium;
    padding: 8px 45px 8px; }
  &_primary {
    background-color: #629200;
    @include md {
      font-size: 4vw;
      padding: 1.2vw 5vw 1.2vw; } }
  &_secondary {
    background-color: #86c800;
    font-size: 24px;
    @include semi;
    padding: 6px 36px 6px; }
  strong {
    @include semi; } }
