.background-wrapper {
  position: relative;
  z-index: -1; }
.background {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  user-select: none;
  &__lamp {
    position: absolute;
    top: -500px;
    left: 16.9%;
    .icon {
      font-size: 935px;
      fill: #fff;
      opacity: 0.2; }
    @include md {
      top: 200px;
      right: 3vw;
      left: auto;
      .icon {
        font-size: 750px; } }
    @include sm {
      top: -300px; }
    //+xs
 }    //  top: -230px
  &__ramadan {
    position: absolute;
    top: 250px;
    right: 265px;
    width: 235px;
    height: 235px;
    opacity: 0.22;
    img {
      transform: rotate(250deg); }
    @include md {
      top: 450px;
      left: -42px;
      width: 266px;
      img {
        transform: rotate(0); } }
    @include xs {
      top: 320px; } }
  &__stars {
    position: absolute;
    width: 1px;
    left: 50%; }
  &__star {
    position: absolute;
    transform: scaleX(-1);

    .icon {
      fill: #fff;
      opacity: 0.46; }
    &_1 {
      top: 294px;
      left: 28.3%;
      .icon {
        font-size: 10px; }
      @include md {
        display: none; } }
    &_2 {
      top: 455px;
      right: 34.8%;

      .icon {
        font-size: 19px; }

      @include md {
        display: none; } }
    &_3 {
      top: 581px;
      left: 18.1%;
      .icon {
        font-size: 14px; }
      @include md {
        left: 11%;
        top: 940px; } }
    &_4 {
      top: 532px;
      right: 3.95%;
      .icon {
        font-size: 10px; }
      @include md {
        top: 753px;
        right: 6%;
        transform: rotate(130deg) scaleX(-1);

        .icon {
          font-size: 25px; } } }
    &_5 {
      top: 853px;
      left: 27.2%;
      transform: scaleX(-1) rotate(53deg);
      .icon {
        font-size: 19px; }
      @include md {
        display: none; } }
    &_6 {
      top: 969px;
      left: 6%;
      .icon {
        font-size: 10px; }
      @include md {
        display: none; } }
    &_7 {
      top: 985px;
      right: 11.4%;
      .icon {
        font-size: 14px; }
      @include md {
        display: none; } } } }
