.shared-list {
  margin-bottom: 100px;
  &__container {
    max-width: 1428px;
    margin: 0 auto;
    padding: 0 42px;
    @include md {
      padding: 0 7.5%; }
    @include sm {
      padding: 0 3%; } }
  &__grid {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 -42px;
    @include md {
      margin: 0 -5.22%; }
    @include sm {
      margin: 0 -4%; } }
  &__item {
    width: 33.33%;
    padding: 0 42px;
    margin-bottom: 84px;
    @include md {
      width: 50%;
      padding: 0 5.22%; }
    @include sm {
      margin-bottom: 8%;
      &:nth-child(even) {
        padding: 0 4% 0 2%; }
      &:nth-child(odd) {
        padding: 0 2% 0 4%; } } }
  &__movie {
    padding-top: 75%;
    width: 100%;
    height: 0;
    background-size: cover;
    background-position: center center; } }
