.photo-collage {
  &__img {
    margin-bottom: 30px; }
  &__title {
    font-size: 33px;
    color: #ffffff;
    @include semi;
    margin-bottom: 20px;
    @include md {
      font-size: 3.6vw;
      margin-bottom: 1.8vw; }
    @include xs {
      font-size: 5vw; } }
  &__movies {
    color: #86c800;
    font-size: 21px;
    @include light;
    line-height: 1.34;
    margin-bottom: 50px;
    @include md {
      font-size: 2.6vw;
      margin-bottom: 4vw; }
    @include xs {
      font-size: 4vw; } } }
