.about {
  position: relative;
  padding-bottom: 180px;
  @include md {
    padding-bottom: 0;
    padding-top: 45vw; }
  @include xs {
    padding-top: 60vw; }
  &__descr {
    body.ar & {
      @include dinarMedium; } }
  &__image {
    width: 100%;
    text-align: center;
    z-index: -1;
    img {
      max-width: 100%;
      height: auto; }
    @include md {
      top: 0;
      left: 0;
      position: absolute;
      margin-left: -15%; } }
  &__container {
    max-width: 1100px;
    margin: 0 auto 110px;
    text-align: center;
    @include md {
      margin-bottom: 15.5vw; } }
  &__title {
    margin-bottom: 45px;
    br {
      display: none; }
    @include md {
      font-size: 9.5vw;
      br {
        display: block; } } }
  &__descr {
    max-width: 950px;
    margin: 0 auto;
    line-height: 2;
    margin-bottom: 50px;
    @include md {
      max-width: none;
      font-size: 3vw;
      line-height: 4vw;
      padding: 0 8vw;
      margin-bottom: 0; } }
  &__partners {
    max-width: 1055px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center; }
  &__partner {
    width: 20%;
    padding: 0 50px;
    margin-bottom: 36px;
    @include md {
      width: 50%;
      padding: 0 13vw;
      margin-bottom: 15vw; } } }
