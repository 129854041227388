html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video { margin: 0; padding: 0; border: 0; vertical-align: baseline; }

html { -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; }

img, fieldset, a img { border: none; }

input[type="text"], input[type="email"], input[type="tel"], textarea { -webkit-appearance: none; }

input[type="submit"], button { cursor: pointer; }

input[type="submit"]::-moz-focus-inner, button::-moz-focus-inner { padding: 0; border: 0; }

textarea { overflow: auto; }

input, button { margin: 0; padding: 0; border: 0; }

div, input, textarea, select, button, h1, h2, h3, h4, h5, h6, a, span, a:focus { outline: none; }

ul, ol { list-style-type: none; }

table { border-spacing: 0; border-collapse: collapse; width: 100%; }

html { box-sizing: border-box; }

*, *:before, *:after { box-sizing: inherit; }

@media (prefers-reduced-motion: reduce) { * { transition: none !important; } }

.icon-facebook { width: 0.53em; height: 1em; fill: none; }

.icon-instagram { width: 1em; height: 1em; fill: none; }

.icon-lamp { width: 0.07em; height: 1em; fill: none; }

.icon-share { width: 0.93em; height: 1em; fill: none; }

.icon-star1 { width: 1.29em; height: 1em; fill: none; }

.icon-star2 { width: 1.31em; height: 1em; fill: none; }

.icon-star3 { width: 1.2em; height: 1em; fill: none; }

.icon-twitter { width: 1.23em; height: 1em; fill: none; }

/* Slider */
.slick-slider { position: relative; display: block; box-sizing: border-box; -moz-box-sizing: border-box; -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; -ms-touch-action: pan-y; touch-action: pan-y; -webkit-tap-highlight-color: transparent; }

.slick-list { position: relative; overflow: hidden; display: block; margin: 0; padding: 0; }

.slick-list:focus { outline: none; }

.slick-list.dragging { cursor: pointer; cursor: hand; }

.slick-slider .slick-track, .slick-slider .slick-list { -webkit-transform: translate3d(0, 0, 0); -moz-transform: translate3d(0, 0, 0); -ms-transform: translate3d(0, 0, 0); -o-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0); }

.slick-track { position: relative; left: 0; top: 0; display: block; }

.slick-track:before, .slick-track:after { content: ""; display: table; }

.slick-track:after { clear: both; }

.slick-loading .slick-track { visibility: hidden; }

.slick-slide { float: left; height: 100%; min-height: 1px; display: none; }

[dir="rtl"] .slick-slide { float: right; }

.slick-slide img { display: block; }

.slick-slide.slick-loading img { display: none; }

.slick-slide.dragging img { pointer-events: none; }

.slick-initialized .slick-slide { display: block; }

.slick-loading .slick-slide { visibility: hidden; }

.slick-vertical .slick-slide { display: block; height: auto; border: 1px solid transparent; }

body { background-color: #101010; font-size: 14px; font-family: 'GillSans', Arial, Helvetica, sans-serif; line-height: 1.4; }

body.ar { font-family: 'GEDinarOne-Medium', GillSans, Arial, Helvetica, sans-serif; }

.container { padding: 0 48px; }

@media only screen and (max-width: 599px) { .container { padding: 0 16px; } }

.out { overflow: hidden; position: relative; }

@media only screen and (max-width: 1125px) { .out { padding-top: 18vw; } }

@media only screen and (max-width: 599px) { .out { padding-top: 23vw; } }

.out_gutter { overflow: hidden; }

@media only screen and (max-width: 1125px) { .out_gutter { padding-top: 20vw; } }

@media only screen and (max-width: 599px) { .out_gutter { padding-top: 35vw; } }

.rtl { direction: rtl; }

.ltr { direction: ltr; }

.img-responsive { max-width: 100%; height: auto; }

.title { font-size: 45px; line-height: 1.2; color: #fff; font-family: 'GillSans-SemiBold', Arial, Helvetica, sans-serif; }

body.ar .title { font-family: 'GEDinarOne-Bold', GillSans-SemiBold, Arial, Helvetica, sans-serif; }

.descr { font-size: 21px; color: #fff; font-family: 'GillSans-Light', Arial, Helvetica, sans-serif; }

body.ar .descr { font-family: 'GEDinarOne-Medium', GillSans, Arial, Helvetica, sans-serif; }

.link { color: #84c205; text-decoration: none; }

.fadeIn { opacity: 0; transform: translateY(30px); transition: 0.6s ease; }

.wf-active .fadeIn, .wf-inactive .fadeIn { opacity: 1; transform: translateY(0); }

#sparks { position: absolute; z-index: 100; pointer-events: none; }

.show-md { display: none; }

@media only screen and (max-width: 1125px) { .show-md { display: inline-block; } }

.hide-md { display: inline-block; }

@media only screen and (max-width: 1125px) { .hide-md { display: none; } }

.header { margin-bottom: 170px; padding-top: 171px; }

@media only screen and (max-width: 1125px) { .header { margin-bottom: 14vw; padding-top: 0; } }

.header__title { margin-bottom: 20px; }

@media only screen and (max-width: 1125px) { .header__title { margin: 0 auto 2vw; font-size: 3em; } }

@media only screen and (max-width: 1000px) { .header__title { font-size: 2.5em; } }

@media only screen and (max-width: 599px) { .header__title { font-size: 1.7em; } }

.header__main { display: flex; justify-content: space-between; align-items: center; padding: 44px 48px 85px; position: relative; position: fixed; top: 0; left: 0; right: 0; z-index: 2; transition: padding 0.3s, background-color 0.3s; }

@media only screen and (max-width: 1125px) { .header__main { padding: 10px 0; padding-right: 6vw; padding-left: 35vw; background-color: #101010; } }

@media only screen and (max-width: 599px) { .header__main { padding-left: 45vw; } }

@media only screen and (min-width: 1126px) { .header__main.is-small { background-color: #101010; padding: 20px 48px; } }

.header__logo { width: 98px; margin-left: auto; flex-grow: 0; flex-shrink: 0; }

@media only screen and (max-width: 1125px) { .header__logo { width: 14vw; } }

@media only screen and (max-width: 800px) { .header__logo { width: 18vw; } }

@media only screen and (max-width: 599px) { .header__logo { width: 20vw; } }

@media only screen and (max-width: 400px) { .header__logo { width: 22vw; } }

.header__descr { color: rgba(255, 255, 255, 0.8); }

@media only screen and (max-width: 1125px) { .header__descr { font-size: 1.6em; max-width: 88%; margin-left: auto; margin-right: auto; } }

@media only screen and (max-width: 1000px) { .header__descr { max-width: 80%; } }

@media only screen and (max-width: 599px) { .header__descr { font-size: 1em; } }

.header__content { text-align: center; max-width: 800px; margin: 0 auto; }

@media only screen and (max-width: 1125px) { .header__content { max-width: 74%; } }

@media only screen and (max-width: 1000px) { .header__content { max-width: 76%; } }

@media only screen and (max-width: 800px) { .header__content { max-width: 80%; } }

@media only screen and (max-width: 767px) { .header__content { max-width: 90%; } }

@media only screen and (max-width: 599px) { .header__content { max-width: 100%; } }

.header__ramadan { max-width: 295px; margin: 0 auto 36px; }

@media only screen and (max-width: 1125px) { .header__ramadan { max-width: 100%; width: 36vw; } }

.header__sticky-text { position: absolute; left: 0%; top: 25%; padding: 0 98px; width: 100%; text-align: center; transition: opacity 0.3s; opacity: 0; z-index: -1; }

@media only screen and (max-width: 1125px) { .header__sticky-text { display: none; position: static; flex-grow: 1; flex-shrink: 1; padding: 0 20px; width: auto; text-align: left; } }

.header__sticky-text strong { color: #fff; font-family: 'GEDinarOne-Bold', GillSans-SemiBold, Arial, Helvetica, sans-serif; font-size: 24px; }

@media only screen and (max-width: 1125px) { .header__sticky-text strong { font-size: 18px; } }

@media only screen and (max-width: 599px) { .header__sticky-text strong { font-size: 12px; } }

.header__sticky-text.is-visible { opacity: 1; }

.background-wrapper { position: relative; z-index: -1; }

.background { position: fixed; z-index: -1; top: 0; left: 0; right: 0; bottom: 0; pointer-events: none; user-select: none; }

.background__lamp { position: absolute; top: -500px; left: 16.9%; }

.background__lamp .icon { font-size: 935px; fill: #fff; opacity: 0.2; }

@media only screen and (max-width: 1125px) { .background__lamp { top: 200px; right: 3vw; left: auto; }
  .background__lamp .icon { font-size: 750px; } }

@media only screen and (max-width: 767px) { .background__lamp { top: -300px; } }

.background__ramadan { position: absolute; top: 250px; right: 265px; width: 235px; height: 235px; opacity: 0.22; }

.background__ramadan img { transform: rotate(250deg); }

@media only screen and (max-width: 1125px) { .background__ramadan { top: 450px; left: -42px; width: 266px; }
  .background__ramadan img { transform: rotate(0); } }

@media only screen and (max-width: 599px) { .background__ramadan { top: 320px; } }

.background__stars { position: absolute; width: 1px; left: 50%; }

.background__star { position: absolute; transform: scaleX(-1); }

.background__star .icon { fill: #fff; opacity: 0.46; }

.background__star_1 { top: 294px; left: 28.3%; }

.background__star_1 .icon { font-size: 10px; }

@media only screen and (max-width: 1125px) { .background__star_1 { display: none; } }

.background__star_2 { top: 455px; right: 34.8%; }

.background__star_2 .icon { font-size: 19px; }

@media only screen and (max-width: 1125px) { .background__star_2 { display: none; } }

.background__star_3 { top: 581px; left: 18.1%; }

.background__star_3 .icon { font-size: 14px; }

@media only screen and (max-width: 1125px) { .background__star_3 { left: 11%; top: 940px; } }

.background__star_4 { top: 532px; right: 3.95%; }

.background__star_4 .icon { font-size: 10px; }

@media only screen and (max-width: 1125px) { .background__star_4 { top: 753px; right: 6%; transform: rotate(130deg) scaleX(-1); }
  .background__star_4 .icon { font-size: 25px; } }

.background__star_5 { top: 853px; left: 27.2%; transform: scaleX(-1) rotate(53deg); }

.background__star_5 .icon { font-size: 19px; }

@media only screen and (max-width: 1125px) { .background__star_5 { display: none; } }

.background__star_6 { top: 969px; left: 6%; }

.background__star_6 .icon { font-size: 10px; }

@media only screen and (max-width: 1125px) { .background__star_6 { display: none; } }

.background__star_7 { top: 985px; right: 11.4%; }

.background__star_7 .icon { font-size: 14px; }

@media only screen and (max-width: 1125px) { .background__star_7 { display: none; } }

.movies { margin-bottom: 200px; padding: 0 42px; position: relative; }

@media only screen and (max-width: 1125px) { .movies { margin-bottom: 24vw; } }

@media only screen and (max-width: 599px) { .movies { padding: 0; } }

.movies__container { max-width: 1428px; margin: 0 auto; padding: 0 42px; }

@media only screen and (max-width: 1125px) { .movies__container { padding: 0 7.5%; } }

@media only screen and (max-width: 767px) { .movies__container { padding: 0 3%; } }

.movies__form { display: flex; margin: 0 -42px; }

@media only screen and (max-width: 1125px) { .movies__form { margin: 0 -5.22%; } }

@media only screen and (max-width: 767px) { .movies__form { margin: 0 -4%; } }

.movies__column { padding: 0 42px; max-width: 33.33%; min-width: 33.33%; }

@media only screen and (max-width: 1125px) { .movies__column { max-width: 50%; min-width: 50%; padding: 0 5.22%; }
  .movies__column:nth-child(3) { display: none; } }

@media only screen and (max-width: 767px) { .movies__column:nth-child(1) { padding: 0 2% 0 4%; }
  .movies__column:nth-child(2) { padding: 0 4% 0 2%; } }

.movies__item { margin-bottom: 84px; perspective: 800px; }

@media only screen and (max-width: 767px) { .movies__item { margin-bottom: 8%; } }

.movies_gutter { margin-bottom: 100px; }

.movie { overflow: hidden; position: relative; transition: .3s ease; transform-origin: 50% 100%; transform: scale(1) rotateX(0); background-color: transparent; height: 0; }

.movie.is-image-loading { background-color: black; }

.movie.is-image-loading .movie__link, .movie.is-image-loading .movie__checkbox { display: none; }

.movie__image { position: absolute; top: 0; left: 0; width: 100%; height: 100%; font-size: 0; transition: .3s ease; transform: translate3d(0, 0, 0); }

.movie__image img { opacity: 0; transform: scale(0.5); transition: .5s ease; }

.movie__image img.is-loaded { opacity: 1; transform: scale(1); }

.movie__link { background-color: rgba(0, 0, 0, 0.59); color: #86c800; text-transform: uppercase; font-size: 16.5px; text-align: center; padding: 13px 10px 14px; position: absolute; left: 0; right: 0; bottom: 0; z-index: 1; transform: translateY(100%); transition: .25s ease; text-decoration: none; }

@media only screen and (max-width: 1125px) { .movie__link { width: 4.5vw; height: 4.5vw; border-radius: 50%; position: absolute; top: 2%; left: 2%; right: auto; transform: none; transition: none; color: rgba(255, 255, 255, 0); padding: 0; }
  .movie__link:before { content: ''; display: block; width: 2.667vw; height: 2.667vw; position: absolute; top: 50%; left: 50%; margin-top: -1.3vw; margin-left: -1.3vw; border-radius: 50%; border: 1px solid #fff; }
  .movie__link:after { content: 'i'; font-family: 'GillSans', Arial, Helvetica, sans-serif; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); color: #fff; font-size: 2vw; text-transform: lowercase; } }

@media only screen and (max-width: 599px) { .movie__link { width: 6.972vw; height: 6.972vw; }
  .movie__link:before { width: 3.486vw; height: 3.486vw; margin-top: -1.743vw; margin-left: -1.743vw; }
  .movie__link:after { font-size: 2.4vw; } }

.movie__checkbox { position: absolute; height: 100%; top: 0; left: 0; width: 100%; z-index: 1; background-color: transparent; }

.movie__checkbox label { position: absolute; top: 0; left: 0; right: 0; bottom: 0; cursor: pointer; }

.movie__checkbox input { display: none; }

.movie__checkbox input:checked ~ .movie__selected, .movie__checkbox input:checked ~ .movie__cover { visibility: visible; opacity: 1; }

.movie__checkbox input:checked + .movie__select { display: none; }

.movie__cover { position: absolute; top: 0; left: 0; right: 0; bottom: 0; background-color: rgba(0, 0, 0, 0.73); opacity: 0; visibility: hidden; transition: .25s ease; z-index: -2; }

.movie__select { visibility: hidden; opacity: 0; transition: .25s ease; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); width: 56px; height: 56px; border-radius: 50%; border: 2px solid rgba(255, 255, 255, 0.8); z-index: -1; pointer-events: none; }

.movie__select:before, .movie__select:after { content: ''; position: absolute; top: 50%; left: 50%; background-color: rgba(255, 255, 255, 0.8); }

.movie__select:before { width: 2px; height: 26px; margin-top: -13px; margin-left: -1px; }

@media only screen and (max-width: 1125px) { .movie__select:before { height: 3.2vw; margin-top: -1.6vw; } }

.movie__select:after { width: 26px; height: 2px; margin-top: -1px; margin-left: -13px; }

@media only screen and (max-width: 1125px) { .movie__select:after { width: 3.2vw; margin-left: -1.6vw; } }

@media only screen and (max-width: 1125px) { .movie__select { width: 6.6vw; height: 6.6vw; visibility: visible; opacity: 1; top: auto; transform: translateX(-50%); bottom: 2.8vw; margin-top: 0; border-width: 1px; } }

.movie__selected { visibility: hidden; opacity: 0; position: absolute; top: 50%; left: 50%; margin-top: -28px; margin-left: -28px; width: 56px; height: 56px; border-radius: 50%; border: 2px solid #8bcf01; z-index: -1; }

.movie__selected:before { content: ''; position: absolute; top: 50%; left: 50%; width: 12px; height: 24px; border-right: 2px solid #8bcf01; border-bottom: 2px solid #8bcf01; transform: rotate(45deg); margin-top: -14px; margin-left: -6px; }

@media only screen and (max-width: 1125px) { .movie__selected { width: 6vw; height: 6vw; margin-top: -3vw; margin-left: -3vw; }
  .movie__selected:before { width: 1.2vw; height: 2.4vw; margin-top: -1.2vw; margin-left: -.6vw; } }

.no-touch .movie:hover .movie__link { transform: translateY(0); }

.no-touch .movie:hover .movie__select { visibility: visible; opacity: 1; }

.no-touch .movie:hover:not(.is-image-loading) { transform: scale(0.97) rotateX(-3deg); }

.no-touch .movie:hover:not(.is-image-loading) .movie__image { opacity: 0.7; }

.about { position: relative; padding-bottom: 180px; }

@media only screen and (max-width: 1125px) { .about { padding-bottom: 0; padding-top: 45vw; } }

@media only screen and (max-width: 599px) { .about { padding-top: 60vw; } }

body.ar .about__descr { font-family: 'GEDinarOne-Medium', GillSans, Arial, Helvetica, sans-serif; }

.about__image { width: 100%; text-align: center; z-index: -1; }

.about__image img { max-width: 100%; height: auto; }

@media only screen and (max-width: 1125px) { .about__image { top: 0; left: 0; position: absolute; margin-left: -15%; } }

.about__container { max-width: 1100px; margin: 0 auto 110px; text-align: center; }

@media only screen and (max-width: 1125px) { .about__container { margin-bottom: 15.5vw; } }

.about__title { margin-bottom: 45px; }

.about__title br { display: none; }

@media only screen and (max-width: 1125px) { .about__title { font-size: 9.5vw; }
  .about__title br { display: block; } }

.about__descr { max-width: 950px; margin: 0 auto; line-height: 2; margin-bottom: 50px; }

@media only screen and (max-width: 1125px) { .about__descr { max-width: none; font-size: 3vw; line-height: 4vw; padding: 0 8vw; margin-bottom: 0; } }

.about__partners { max-width: 1055px; margin: 0 auto; display: flex; flex-wrap: wrap; justify-content: center; align-items: center; }

.about__partner { width: 20%; padding: 0 50px; margin-bottom: 36px; }

@media only screen and (max-width: 1125px) { .about__partner { width: 50%; padding: 0 13vw; margin-bottom: 15vw; } }

.btn { border-radius: 5px; display: inline-block; text-decoration: none; font-size: 29px; padding: 9px 45px 6px; color: #fff; font-family: 'GillSans-Light', Arial, Helvetica, sans-serif; }

body.ar .btn { font-family: 'GEDinarOne-Medium', GillSans, Arial, Helvetica, sans-serif; padding: 8px 45px 8px; }

.btn_primary { background-color: #629200; }

@media only screen and (max-width: 1125px) { .btn_primary { font-size: 4vw; padding: 1.2vw 5vw 1.2vw; } }

.btn_secondary { background-color: #86c800; font-size: 24px; font-family: 'GillSans-SemiBold', Arial, Helvetica, sans-serif; padding: 6px 36px 6px; }

.btn strong { font-family: 'GillSans-SemiBold', Arial, Helvetica, sans-serif; }

.footer { background-color: black; padding-top: 158px; padding-bottom: 30px; }

@media only screen and (max-width: 1125px) { .footer { padding-top: 13.6vw; padding-bottom: 13.6vw; } }

.footer__content { text-align: center; margin-bottom: 160px; }

@media only screen and (max-width: 1125px) { .footer__content { margin-bottom: 0; } }

.footer__title { margin-bottom: 47px; }

@media only screen and (max-width: 1125px) { .footer__title { font-size: 6vw; padding: 0 6vw; } }

.footer__bottom { display: flex; justify-content: flex-start; }

.footer__copy { padding-left: 36px; font-size: 17px; letter-spacing: -.2px; color: rgba(255, 255, 255, 0.87); text-align: left; font-family: 'GillSans-Light', Arial, Helvetica, sans-serif; }

@media only screen and (max-width: 1125px) { .footer__copy { display: none; } }

body.ar .footer__copy { font-family: 'GEDinarOne-Medium', GillSans, Arial, Helvetica, sans-serif; }

.footer__legal { color: #fff; font-family: 'GillSans-Light', Arial, Helvetica, sans-serif; font-size: 17px; margin-left: 20px; max-width: 500px; }

.footer__legal a { color: #fff; text-decoration: none; }

@media only screen and (max-width: 1125px) { .footer__legal { display: none; } }

.sharer { position: fixed; right: 47px; bottom: 100px; z-index: 2; text-align: right; z-index: 10; }

@media only screen and (max-width: 1820px) { .sharer { padding: 15px; background-color: rgba(16, 16, 16, 0.4); } }

@media only screen and (max-width: 1125px) { .sharer { padding: 0; background-color: tranparent; top: 0; right: 0; left: 0; bottom: auto; background-color: rgba(16, 16, 16, 0); } }

.sharer__form { position: relative; }

.sharer__form.is-socials .sharer__socials { visibility: visible; opacity: 1; }

.sharer__socials { display: none; }

.sharer__list { margin-bottom: 40px; }

@media only screen and (max-width: 1125px) { .sharer__list { width: 100%; margin-bottom: 0; height: 11vw; background-color: #686355; display: flex; padding: .8vw 0 .8vw 6.3vw; position: relative; }
  .sharer__list .slick-list { width: 100%; width: calc(100% - 6vw); }
  .sharer__list .slick-prev, .sharer__list .slick-next { position: absolute; top: 0; height: 100%; background-color: transparent; color: rgba(255, 255, 255, 0); }
  .sharer__list .slick-prev { position: absolute; height: 100%; left: 0; width: 6vw; }
  .sharer__list .slick-prev:before { content: ''; position: absolute; top: 50%; height: 3vw; width: 3vw; color: rgba(255, 255, 255, 0); background-color: transparent; border-width: 2px; margin-top: -1.5vw; left: 2vw; border-left: 2px solid #fff; border-bottom: 2px solid #fff; transform: rotate(45deg); }
  .sharer__list .slick-next { width: 10vw; right: 0; background-image: linear-gradient(to left, rgba(0, 0, 0, 0.66) 0%, rgba(0, 0, 0, 0) 100%); }
  .sharer__list .slick-next:before { content: ''; border-right: 2px solid #fff; border-bottom: 2px solid #fff; transform: rotate(-45deg); position: absolute; top: 50%; right: 2vw; margin-top: -1.5vw; height: 3vw; width: 3vw; transform: rotate(-45deg); } }

@media only screen and (max-width: 599px) { .sharer__list { height: 20vw; padding: 1.5vw 0 1.5vw 6.3vw; }
  .sharer__list .slick-prev:before { left: 3vw; }
  .sharer__list .slick-next:before { right: 3vw; } }

@media only screen and (max-width: 599px) and (orientation: landscape) { .sharer__list { max-height: 90px; } }

.sharer__item:not(:last-child) { margin-bottom: 10px; }

@media only screen and (max-width: 1125px) { .sharer__item:not(:last-child) { margin-right: 2.6vw; margin-bottom: 0; } }

@media only screen and (max-width: 599px) { .sharer__item:not(:last-child) { margin-right: 3.5vw; } }

@media only screen and (max-width: 1125px) { .sharer__item { width: 18vw; height: 9vw; } }

@media only screen and (max-width: 599px) { .sharer__item { height: 17vw; width: 34vw; } }

@media only screen and (max-width: 599px) and (orientation: landscape) { .sharer__item { height: 14vw !important; } }

@media only screen and (max-width: 1125px) { .sharer__checkbox { position: relative; height: 100%; } }

.sharer__text { color: rgba(255, 255, 255, 0.6); position: relative; font-size: 17px; text-align: right; }

.sharer__text:before { content: ''; position: absolute; top: 50%; left: -3px; right: -3px; height: 1px; background-color: #85c600; transform: scaleX(0); transition: .25s ease; transform-origin: right center; }

@media only screen and (max-width: 1125px) { .sharer__text { display: none; } }

.sharer__label { display: flex; align-items: center; cursor: pointer; justify-content: flex-end; }

.sharer__label input { display: none; }

@media only screen and (min-width: 1125px) { .sharer__label:hover .sharer__text { color: #85c600; }
  .sharer__label:hover .sharer__text:before { transform: scaleX(1); }
  .sharer__label:hover .sharer__remove { display: block; }
  .sharer__label:hover .sharer__selected { display: none; } }

.sharer__selected { display: block; margin-left: 20px; width: 12px; height: 12px; border-radius: 50%; border: 2px solid rgba(255, 255, 255, 0.6); }

@media only screen and (max-width: 1125px) { .sharer__selected { width: 3.6vw; height: 3.6vw; border-radius: 50%; border: 1px solid #fff; top: 50%; left: 50%; transform: translate(-50%, -50%); margin-left: 0; z-index: 1; position: absolute; }
  .sharer__selected:before, .sharer__selected:after { content: ''; position: absolute; top: 50%; left: 50%; margin-left: -0.9vw; width: 1.8vw; margin-top: -1px; height: 1px; display: block; background-color: #fff; }
  .sharer__selected:before { transform: rotate(45deg); }
  .sharer__selected:after { transform: rotate(-45deg); } }

@media only screen and (max-width: 599px) { .sharer__selected { width: 6vw; height: 6vw; }
  .sharer__selected:before, .sharer__selected:after { width: 3vw; margin-left: -1.5vw; } }

@media only screen and (max-width: 450px) { .sharer__selected { width: 8vw; height: 8vw; }
  .sharer__selected:before, .sharer__selected:after { width: 4vw; margin-left: -2vw; } }

.sharer__image { display: none; }

@media only screen and (max-width: 1125px) { .sharer__image { display: block; position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-size: cover; background-position: top center; } }

.sharer__remove { display: none; margin-left: 20px; position: relative; width: 12px; height: 12px; overflow: hidden; }

.sharer__remove:before, .sharer__remove:after { content: ''; position: absolute; top: 50%; left: 0; margin-top: -1px; height: 1px; display: block; width: 100%; background-color: #85c600; }

.sharer__remove:before { transform: rotate(45deg); }

.sharer__remove:after { transform: rotate(-45deg); }

.sharer__form.is-hide { display: none; }

.sharer__form.is-pending .sharer__button-text { display: none; }

.sharer__form.is-pending .sharer__pending { display: inline-block; animation: rotate-infinite 1s linear infinite; }

.sharer__pending { position: relative; top: 2px; display: none; width: 20px; height: 20px; border-radius: 50%; border: 2px solid #fff; border-top-color: transparent; }

@media only screen and (max-width: 1125px) { .sharer__pending { width: 4vw; height: 4vw; top: 0; } }

@media only screen and (max-width: 767px) { .sharer__pending { width: 30px; height: 30px; } }

.sharer__button { text-align: center; min-height: 39px; min-width: 104px; display: inline-block; color: #fff; padding: 6px 25px; font-size: 22px; font-family: 'GillSans-SemiBold', Arial, Helvetica, sans-serif; border-radius: 5px; background-color: #86c800; }

.sharer__button .icon { display: none; }

@media only screen and (max-width: 1125px) { .sharer__button { background-color: #81b812; border-radius: 0; margin: 10px 15px 0; width: calc(100% - 30px); min-height: 60px; display: flex; align-items: center; justify-content: center; position: relative; font-size: 4.2vw; padding: 0 20px; letter-spacing: 0.2vw; z-index: 3; font-family: 'GillSans', Arial, Helvetica, sans-serif; }
  .sharer__button .icon { display: block; font-size: 3.5vw; fill: #fff; margin-right: 1vw; } }

@media only screen and (max-width: 900px) { .sharer__button { min-height: 50px; } }

@media only screen and (max-width: 767px) { .sharer__button { font-size: 32px; min-height: 40px; }
  .sharer__button .icon { font-size: 26px; margin-right: 2vw; } }

@keyframes rotate-infinite { 0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); } }

.popup { position: fixed; top: 0; left: 0; bottom: 0; right: 0; display: flex; justify-content: center; align-items: center; display: none; overflow-y: auto; z-index: 100; }

.popup__inner { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 1; background-color: #070707; width: 100%; max-width: 750px; margin: 0 auto; padding: 32px 28px 45px; }

@media only screen and (max-width: 1125px) { .popup__inner { max-width: 918px; padding: 3.6vw 3.6vw 4vw; } }

.popup__cover { position: fixed; top: 0; left: 0; right: 0; bottom: 0; cursor: pointer; z-index: -1; background-color: rgba(16, 16, 16, 0.8); }

.popup__close { position: absolute; top: -30px; right: -30px; width: 20px; height: 20px; z-index: 1; cursor: pointer; }

.popup__close:before, .popup__close:after { content: ''; position: absolute; top: 50%; left: 0; margin-top: -1px; height: 1px; display: block; width: 100%; background-color: #79ac10; }

.popup__close:before { transform: rotate(45deg); }

.popup__close:after { transform: rotate(-45deg); }

@media only screen and (max-width: 1125px) { .popup__close { right: 15px; } }

.popup.is-show { display: block; }

.socials { margin: 0 -7px; display: flex; }

@media only screen and (max-width: 1125px) { .socials { margin: 0 -.7vw; } }

@media only screen and (max-width: 599px) { .socials { margin: 0; flex-wrap: wrap; } }

.socials__item { width: 33.33%; padding: 0 7px; }

@media only screen and (max-width: 1125px) { .socials__item { padding: 0 .7vw; } }

@media only screen and (max-width: 599px) { .socials__item { width: 100%; padding: 0; }
  .socials__item:not(:last-child) { margin-bottom: 10px; } }

.social { padding: 8px 10px; width: 100%; display: inline-block; text-align: center; text-decoration: none; color: #fff; font-size: 17px; border-radius: 3px; font-family: 'GillSans-SemiBold', Arial, Helvetica, sans-serif; }

.social_facebook { background-color: #385695; }

.social_twitter { background-color: #00a8ec; }

.social_inst { background-color: #ea0e51; }

@media only screen and (max-width: 1125px) { .social { font-size: 1.85vw; padding: 1.823vw 10px; } }

@media only screen and (max-width: 599px) { .social { font-size: 3vw; display: flex; height: 100%; align-items: center; justify-content: center; } }

@media only screen and (max-width: 400px) { .social { font-size: 4vw; } }

.photo-collage__img { margin-bottom: 30px; }

.photo-collage__title { font-size: 33px; color: #ffffff; font-family: 'GillSans-SemiBold', Arial, Helvetica, sans-serif; margin-bottom: 20px; }

@media only screen and (max-width: 1125px) { .photo-collage__title { font-size: 3.6vw; margin-bottom: 1.8vw; } }

@media only screen and (max-width: 599px) { .photo-collage__title { font-size: 5vw; } }

.photo-collage__movies { color: #86c800; font-size: 21px; font-family: 'GillSans-Light', Arial, Helvetica, sans-serif; line-height: 1.34; margin-bottom: 50px; }

@media only screen and (max-width: 1125px) { .photo-collage__movies { font-size: 2.6vw; margin-bottom: 4vw; } }

@media only screen and (max-width: 599px) { .photo-collage__movies { font-size: 4vw; } }

.tooltip { position: fixed; bottom: 0; left: 0; right: 0; z-index: 9; background-color: rgba(0, 0, 0, 0.79); padding: 34px 0; display: flex; align-items: center; }

@media only screen and (max-width: 1125px) { .tooltip { padding: 5vw 0; } }

.tooltip__container { max-width: 1428px; padding: 0 42px; margin: 0 auto; height: 100%; width: 100%; display: flex; padding-right: 140px; align-items: center; justify-content: space-between; }

@media only screen and (max-width: 1125px) { .tooltip__container { padding-right: 4vw; padding-left: 4vw; } }

.tooltip__title { font-size: 29px; font-family: 'GillSans-SemiBold', Arial, Helvetica, sans-serif; color: #fff; }

.tooltip__title br { display: none; }

body.ar .tooltip__title { font-family: 'GEDinarOne-Medium', GillSans, Arial, Helvetica, sans-serif; }

@media only screen and (max-width: 1125px) { .tooltip__title { font-size: 3.3vw; line-height: 1.2; }
  .tooltip__title br { display: block; } }

@media only screen and (max-width: 599px) { .tooltip__title { font-size: 4vw; } }

.tooltip__button { color: #fff; border-radius: 5px; display: inline-block; text-decoration: none; background-color: #86c800; font-size: 24px; text-align: center; font-family: 'GillSans-SemiBold', Arial, Helvetica, sans-serif; padding: 6px 36px 6px; }

body.ar .tooltip__button { font-family: 'GEDinarOne-Medium', GillSans, Arial, Helvetica, sans-serif; }

@media only screen and (max-width: 1125px) { .tooltip__button { font-size: 2.5vw; padding: 1.3vw 3vw; min-width: 36vw; } }

@media only screen and (max-width: 599px) { .tooltip__button { padding: 2.2vw 3vw; min-width: 40vw; font-size: 4vw; } }

.coming-soon { margin-bottom: 230px; padding: 0 42px; }

@media only screen and (max-width: 1125px) { .coming-soon { margin-bottom: 24vw; } }

@media only screen and (max-width: 599px) { .coming-soon { padding: 0; } }

.coming-soon__title { margin-bottom: 150px; text-align: center; }

.coming-soon__list { display: flex; margin: 0 -42px; }

@media only screen and (max-width: 1125px) { .coming-soon__list { margin: 0 -5.22%; display: none; } }

@media only screen and (max-width: 767px) { .coming-soon__list { margin: 0 -4%; } }

.coming-soon__list_mob { display: none; }

@media only screen and (max-width: 1125px) { .coming-soon__list_mob { display: flex; } }

.coming-soon__column { padding: 0 42px; width: 33.33%; }

@media only screen and (max-width: 1125px) { .coming-soon__column { max-width: 50%; min-width: 50%; padding: 0 5.22%; }
  .coming-soon__column:nth-child(3) { display: none; } }

@media only screen and (max-width: 767px) { .coming-soon__column { padding: 0 4%; } }

.coming-soon__item { margin-bottom: 80px; font-size: 0; }

@media only screen and (max-width: 1125px) { .coming-soon__item { margin-bottom: 15vw; } }

.coming-soon__container { max-width: 1428px; margin: 0 auto; padding: 0 42px; }

@media only screen and (max-width: 1125px) { .coming-soon__container { padding: 0 7.5%; } }

@media only screen and (max-width: 767px) { .coming-soon__container { padding: 0 3%; } }

.shared-list { margin-bottom: 100px; }

.shared-list__container { max-width: 1428px; margin: 0 auto; padding: 0 42px; }

@media only screen and (max-width: 1125px) { .shared-list__container { padding: 0 7.5%; } }

@media only screen and (max-width: 767px) { .shared-list__container { padding: 0 3%; } }

.shared-list__grid { display: flex; justify-content: center; flex-wrap: wrap; margin: 0 -42px; }

@media only screen and (max-width: 1125px) { .shared-list__grid { margin: 0 -5.22%; } }

@media only screen and (max-width: 767px) { .shared-list__grid { margin: 0 -4%; } }

.shared-list__item { width: 33.33%; padding: 0 42px; margin-bottom: 84px; }

@media only screen and (max-width: 1125px) { .shared-list__item { width: 50%; padding: 0 5.22%; } }

@media only screen and (max-width: 767px) { .shared-list__item { margin-bottom: 8%; }
  .shared-list__item:nth-child(even) { padding: 0 4% 0 2%; }
  .shared-list__item:nth-child(odd) { padding: 0 2% 0 4%; } }

.shared-list__movie { padding-top: 75%; width: 100%; height: 0; background-size: cover; background-position: center center; }
