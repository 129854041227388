.sharer {
	$this: &;
	position: fixed;
	right: 47px;
	bottom: 100px;
	z-index: 2;
	text-align: right;
	z-index: 10;
	@include r(1820) {
		padding: 15px;
		background-color: rgba(#101010, .4); }
	@include md {
		padding: 0;
		background-color: tranparent;
		top: 0;
		right: 0;
		left: 0;
		bottom: auto;
		background-color: rgba(#101010, .0); }

	&__form {
		position: relative;
		&.is-socials {
			#{$this}__socials {
				visibility: visible;
				opacity: 1; } } }
	&__socials {
		display: none; }
	&__list {
		margin-bottom: 40px;
		@include md {
			width: 100%;
			margin-bottom: 0;
			height: 11vw;
			// max-height: 100px
			background-color: #686355;
			display: flex;
			padding: .8vw 0 .8vw 6.3vw;
			position: relative;
			.slick-list {
				width: 100%;
				width: calc(100% - 6vw); }
			.slick-prev, .slick-next {
				position: absolute;
				top: 0;
				height: 100%;
				background-color: transparent;
				color: rgba(white, 0); }
			.slick-prev {
				position: absolute;
				height: 100%;
				left: 0;
				width: 6vw;
				&:before {
					content: '';
					position: absolute;
					top: 50%;
					height: 3vw;
					width: 3vw;
					color: rgba(white, 0);
					background-color: transparent;
					border-width: 2px;
					margin-top: -1.5vw;
					left: 2vw;
					border-left: 2px solid #fff;
					border-bottom: 2px solid #fff;
					transform: rotate(45deg); } }
			.slick-next {
				width: 10vw;
				right: 0;
				background-image: linear-gradient(to left, rgba(black, .66) 0%, rgba(black, .0) 100%);
				&:before {
					content: '';
					border-right: 2px solid #fff;
					border-bottom: 2px solid #fff;
					transform: rotate(-45deg);
					position: absolute;
					top: 50%;
					right: 2vw;
					margin-top: -1.5vw;
					height: 3vw;
					width: 3vw;
					transform: rotate(-45deg); } } }
		@include xs {
			height: 20vw;
			padding: 1.5vw 0 1.5vw 6.3vw;
			.slick-prev {
				&:before {
					left: 3vw; } }
			.slick-next {
				&:before {
					right: 3vw; } } }
		@include xslandscape {
			max-height: 90px; } }
	&__item {
		&:not(:last-child) {
			margin-bottom: 10px;
			@include md {
				margin-right: 2.6vw;
				margin-bottom: 0; }
			@include xs {
				margin-right: 3.5vw; } }
		@include md {
			width: 18vw;
			// width: 15.3vw
			height: 9vw; }
		@include xs {
			height: 17vw;
			width: 34vw; }
		@include xslandscape {
			height: 14vw!important; } }

	&__checkbox {
		@include md {
			position: relative;
			height: 100%; } }
	&__text {
		color: rgba(white, .6);
		position: relative;
		font-size: 17px;
		text-align: right;
		&:before {
			content: '';
			position: absolute;
			top: 50%;
			left: -3px;
			right: -3px;
			height: 1px;
			background-color: #85c600;
			transform: scaleX(0);
			transition: .25s ease;
			transform-origin: right center; }
		@include md {
			display: none; } }
	&__label {
		display: flex;
		align-items: center;
		cursor: pointer;
		justify-content: flex-end;
		input {
			display: none; }
		&:hover {
			@include rmin(1125) {
				#{$this}__text {
					color: #85c600;
					&:before {
						transform: scaleX(1); } }
				#{$this}__remove {
					display: block; }
				#{$this}__selected {
					display: none; } } } }
	&__selected {
		display: block;
		margin-left: 20px;
		width: 12px;
		height: 12px;
		border-radius: 50%;
		border: 2px solid rgba(white, .6);
		@include md {
			width: 3.6vw;
			height: 3.6vw;
			border-radius: 50%;
			border: 1px solid #fff;
			top: 50%;
			left: 50%;
			// margin-top: -1.8vw
			// margin-left: -1.8vw
			transform: translate(-50%, -50%);
			margin-left: 0;
			z-index: 1;
			position: absolute;
			&:before, &:after {
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				margin-left: -0.9vw;
				width: 1.8vw;
				margin-top: -1px;
				height: 1px;
				display: block;
				background-color: #fff; }
			&:before {
				transform: rotate(45deg); }
			&:after {
				transform: rotate(-45deg); } }
		@include xs {
			width: 6vw;
			height: 6vw;

			// margin-top: -3vw
			// margin-left: -3vw

			&:before, &:after {
				width: 3vw;
				margin-left: -1.5vw; } }
		@include r(450) {
			width: 8vw;
			height: 8vw;
			// margin-top: -4vw
			// margin-left: -4vw
			&:before, &:after {
				width: 4vw;
				margin-left: -2vw; } } }
	&__image {
		display: none;
		@include md {
			display: block;
			@include coverdiv;
			background-size: cover;
			background-position: top center; } }
	&__remove {
		display: none;
		margin-left: 20px;
		position: relative;
		width: 12px;
		height: 12px;
		overflow: hidden;
		&:before, &:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 0;
			margin-top: -1px;
			height: 1px;
			display: block;
			width: 100%;
			background-color: #85c600; }
		&:before {
			transform: rotate(45deg); }
		&:after {
			transform: rotate(-45deg); } }
	&__form {
		&.is-hide {
			display: none; }
		&.is-pending {
			#{$this}__button-text {
				display: none; }
			#{$this}__pending {
				display: inline-block;
				animation: rotate-infinite 1s linear infinite; } } }
	&__pending {
		position: relative;
		top: 2px;
		display: none;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		border: 2px solid #fff;
		border-top-color: transparent;
		@include md {
			width: 4vw;
			height: 4vw;
			top: 0; }
		@include sm {
			width: 30px;
			height: 30px;
			// margin-top: 1.6vw
 } }			// margin-bottom: 1.6vw

	&__button {
		text-align: center;
		min-height: 39px;
		min-width: 104px;
		display: inline-block;
		color: #fff;
		padding: 6px 25px;
		font-size: 22px;
		@include semi;
		border-radius: 5px;
		background-color: #86c800;
		.icon {
			display: none; }
		@include md {
			background-color:  #81b812;
			border-radius: 0;
			margin: 10px 15px 0;
			width: calc(100% - 30px);
			min-height: 60px;
			display: flex;
			align-items: center;
			justify-content: center;
			//position: fixed
			position: relative;
			//top: 3.7vw
			font-size: 4.2vw;
			padding: 0 20px;
			letter-spacing: 0.2vw;
			z-index: 3;
			@include reg;
			.icon {
				display: block;
				font-size: 3.5vw;
				fill: #fff;
				margin-right: 1vw; } }
		@include r(900) {
			min-height: 50px; }
		@include sm {
			font-size: 32px;
			min-height: 40px;
			.icon {
				font-size: 26px;
				margin-right: 2vw; } } } }


@keyframes rotate-infinite {
	0% {
		transform: rotate(0deg); }
	100% {
		transform: rotate(360deg); } }
