.popup {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
  overflow-y: auto;
  z-index: 100;
  &__inner {
    @include vcenter;
    z-index: 1;
    background-color: #070707;
    width: 100%;
    max-width: 750px;
    margin: 0 auto;
    padding: 32px 28px 45px;
    @include md {
      max-width: 918px;
      padding: 3.6vw 3.6vw 4vw; } }
  &__cover {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    z-index: -1;
    background-color: rgba(#101010, .8); }
  &__close {
    position: absolute;
    top: -30px;
    right: -30px;
    width: 20px;
    height: 20px;
    z-index: 1;
    cursor: pointer;
    &:before, &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -1px;
      height: 1px;
      display: block;
      width: 100%;
      background-color: #79ac10; }
    &:before {
      transform: rotate(45deg); }
    &:after {
      transform: rotate(-45deg); }
    @include md {
      right: 15px; } }
  &.is-show {
    display: block; } }
